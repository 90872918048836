import React, { useRef, useState } from "react"
import { Card, Col, FormGroup, Input, Label, Progress, Row } from "reactstrap"
import Axios from "axios"
import DataTable from "react-data-table-component"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"


const converter = bytes => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
  if (bytes == 0) return "0 Byte"
  let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i]
}


const UploadComponent = ({ title, filename, onUpload, height, onStart, label, onError, video }) => {
  // const {User} = useSelector(state => state);
  // const {token} = User;
  //
  const token = "61|ZmHmvbLrVYozZNyLqpBeyDTNh5hs5Q4vHel26VUh"
  const inputRef = useRef(null)
  const [progress, setProgress] = useState(0)
  const [files, setFiles] = useState([])
  const [status, setStatus] = useState("info")
  // console.log(token)

  const uploadFile = event => {
    onStart()
    setProgress(0)

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${token}`
      },
      onUploadProgress: function(progressEvent) {
        let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setProgress(progress)
      }
    }

    const formData = new FormData()
    formData.append("file", event.target.files[0])
    filename && formData.append("filename", filename)
    // console.log(event.target.files)
    let url = "/upload"
    Axios.post(url, formData, config).then((res) => {

      setFiles(res.data)
      setStatus("success")
      onUpload(res.data)

    }).catch((e) => setStatus("warning"))
  }



  const click = () => {
    // inputRef.current.dispatchEvent(
    //   new MouseEvent('click', {
    //     view: window,
    //     bubbles: true,
    //     cancelable: true,
    //     buttons: 1,
    //   }))
  }
  return (
    <>
      {!video && <FormGroup>
        <Label for="customFile">{title ? title : "Upload File"}</Label>
        <Input
          type="file"
          id="exampleCustomFileBrowser"
          name="file"
          onChange={uploadFile}
        />
      </FormGroup>}
      {video && <Card onClick={click} style={height && { height }}
                      className="bg-white  justify-content-center align-content-center text-center align-items-center ">
        <div>
          {/*<i className="mdi mdi-upload-outline" style={{ fontSize: 60, color: "#cccccc" }}></i>*/}
          {/*<h1 style={{ color: "#ccc", fontSize: 20 }}>{label ? label : "UPLOAD"} </h1>*/}
          <Input
            type="file"
            name="file"
            ref={inputRef}
            onChange={uploadFile}
          />
          {(progress > 0 && progress < 100) && (<Row>
            <Col xs={9}>
              <div className="m-50"><Progress color={status} value={progress} /></div>
            </Col>
            <Col xs={3}>
              <>{progress < 100 ? `${progress} %` : `Complete`} </>
            </Col>
          </Row>)}

        </div>


      </Card>}
      {!video && (progress > 0 && progress < 100) && (<Row>
        <Col xs={9}>
          <div className="m-50"><Progress color={status} value={progress} /></div>
        </Col>
        <Col xs={3}>
          <>{progress < 100 ? `${progress} %` : `Complete`} </>
        </Col>
      </Row>)}
      {/*<pre>{JSON.stringify(files, null, 2)}</pre>*/}
    </>)
}


export default UploadComponent




