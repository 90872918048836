import React, { useRef, useEffect, useState, createRef } from "react"

export default function ScreenShot({ isOpen, video, toggle, height }) {
  const ref = createRef()
  const videoRef = useRef()
  const canvasRef = useRef()
  const [dimensions, setDimensions] = useState({})


  let context
  if (canvasRef.current) {
    context = canvasRef.current.getContext("2d")
  }

  function getVideoSizeData(videoRef) {
    const ratio = videoRef.current.videoWidth / videoRef.current.videoHeight
    const w = videoRef.current.videoWidth - 100
    const h = parseInt(w / ratio, 10)
    return {
      ratio,
      w,
      h
    }
  }

  useEffect(() => {
    // Add listener when the video is actually available for
    // the browser to be able to check the dimensions of the video.
    if (videoRef.current) {
      videoRef.current.addEventListener("loadedmetadata", function() {
        const { w, h } = getVideoSizeData(videoRef)

        canvasRef.current.width = w
        canvasRef.current.height = h
        setDimensions({
          w: w,
          h: h
        })
      })
    }
  }, [])



  function snap() {


    if (context && videoRef.current) {
      context.fillRect(0, 0, dimensions.w, dimensions.h)
      context.drawImage(videoRef.current, 0, 0, dimensions.w, dimensions.h)
    }
  }

  return (<div>
      <div ref={ref}>
        <video
          src="http://api.appskill.africa/api/file/?id=93"
          ref={videoRef}
          controls
          style={{ width: "100%", height }}
        />
      </div>

      <canvas ref={canvasRef} style={{ width: 300, maxHeight: height }} />
      <button onClick={snap}>Take screenshot</button>
    </div>
  )
}
