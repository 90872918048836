import React from "react"
import { LessonListComponent } from "../../lessons/components/lessonList"
import LessonFormCanvas from "../../lessons/form/lessonFormCanvas"
import DataTable from "react-data-table-component"
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { Link } from "react-router-dom"
import { LessonStatus } from "../../../components/app/statusBudges"
import { lessonDelete, lessonPublish } from "../../../store/lessons/lessonActions"


export default class LessonWizardTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lessons: [],
      form: false
    }
  }


  render() {
    const { lessons, form } = this.state

    const toggle = () => {
      this.setState({ form: !form })
    }


    const columns = [
      {
        name: "",
        selector: "id",
        width: "90px",
        cell: (row) => <img
          width={50} height={50} src={row.video && row.video.thumbnail}
          className="rounded-1 shadow-sm m-2" />
      },
      {
        name: "Lesson Name",
        wrap: true,
        selector: "title",
        sortable: true,
        cell: (row) => <div>
          <div>
            <Link to={`/course/lesson/${row.id}`} className="text-dark font-size-17">{row.title}</Link>
          </div>
        </div>
      },

      {
        name: "",
        right: true,
        selector: "id",
        width: "60px",
        cell: row => <Button size="sm" color="danger"><i className="bx bx-x "></i></Button>
      }


    ]

    return <React.Fragment>
      <div className="text-center">
        <Button outline color="primary" onClick={() => toggle()}>Create <i className="bx bx-plus"></i></Button>

      </div>
      <DataTable columns={columns} data={lessons} />


      <LessonFormCanvas
        lessonId={0}
        isOpen={form}
        callBack={(fields) => {
          let data = lessons
          data.push(fields)
          this.setState({ lessons: data }, () => this.props.updateLessons(data))
          toggle()
        }}
        toggle={() => toggle()} courseId={0} />
    </React.Fragment>

  }

}
