import React, { useEffect, useState } from "react"
import UploadComponent from "./upload"
import ScreenShot  from "./ScreenShot"
import { Button } from "reactstrap"


export const UploadVideo = ({ title, video, updateVideo, height, label }) => {
  const [cVideo, setCVideo] = useState(video)
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    cVideo && setIsOpen(true)

  }, [cVideo])

  return <React.Fragment>
    {!cVideo && <UploadComponent
      label={label}
      height={height}
      video
      title={title ? title : "Upload Video"}
      onStart={() => updateVideo(false)}
      filename="video"
      onUpload={data => {
        updateVideo(data)
        setCVideo(data)
      }}
    />}

    {cVideo && <ScreenShot height={height} isOpen={isOpen} toggle={() => setIsOpen(false)} video={cVideo} />}
    {cVideo && <Button onClick={() => setCVideo(false)}>Delete</Button> }


  </React.Fragment>
}
