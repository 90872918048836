import React, { useState } from "react"
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle, Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip
} from "reactstrap"
import { map } from "lodash"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"


import { CourseStatus } from "../../../components/app/statusBudges"
import images from "../../../assets/images"
import { courseDelete, courseGet, coursePublish, courseSubmit } from "../../../store/courses/courseActions"
import CourseFormCanvas from "../form/courseFormCanvas"
import { Image } from "../../../components/app/image"


export const CourseList = ({ loading, courses }) => {
  const [form, setForm] = useState(false)
  const [courseId, setCourseId] = useState(0)
  const dispatch  = useDispatch();
  return <>
    <div className="table-responsive">
      <Table className="project-list-table table-nowrap align-middle table-borderless">
        <thead>
        <tr>
          <th scope="col" style={{ width: "60px" }}>#</th>
          <th scope="col">Course Title</th>
          <th scope="col">Completion</th>
          <th scope="col">Rating</th>
          <th scope="col">Status</th>
          <th scope="col">Current Students</th>
          <th scope="col">Action</th>
        </tr>
        </thead>
        <tbody>
        {courses && map(courses, (course, index) => (
          <tr key={index}>
            <td>
              <Image
                src={course.thumbnail}
                alt=""
                className="avatar-sm rounded-4 shadow-sm"
              />
            </td>
            <td>
              <h5 className="text-truncate font-size-14">
                <Link
                  to={`/course/course/${course.id}`}
                  className="text-dark"
                >
                  {course.title}
                </Link>
              </h5>
              <p className="text-muted mb-0">
                Lessons : {course.lessons} | Comments
                : {course.comments} | <Link>{course.category_title}</Link>
              </p>
            </td>
            <td> {Math.round(Math.random() * 60) + 10} %</td>
            <td> {Math.floor(Math.random() * 5)}.{Math.round(Math.random() + 90)}  </td>
            <td>
              <CourseStatus status={course.status} />
              {/* <Badge className={"bg-" + course.color}>
                              {course.status}
                            </Badge> */}
            </td>
            <td>
              <div className="avatar-group">
                {map([
                    { id: 1, img: "avatar8" },
                    { id: 2, img: "avatar2", fullname: "Daniel Candles" }
                  ], (member, index) =>
                    !member.img || member.img !== "Null" ? (
                      <div
                        className="avatar-group-item"
                        key={index}
                      >
                        <Link
                          to="#"
                          className="team-member d-inline-block"
                          id="member1"
                        >
                          <img
                            src={images[member.img]}
                            className="rounded-circle avatar-xs"
                            alt=""
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target="member1"
                          >
                            {member.name}
                          </UncontrolledTooltip>
                        </Link>
                      </div>
                    ) : (
                      <div
                        className="avatar-group-item"
                        key={"_team_" + index}
                      >
                        <Link
                          to="#"
                          className="d-inline-block"
                          id={"member" + member.id}
                        >
                          <div className="avatar-xs">
                                        <span
                                          className={
                                            "avatar-title rounded-circle bg-soft bg-" +
                                            member.color +
                                            " text-" +
                                            member.color +
                                            " font-size-16"
                                          }
                                        >
                                          {member.name.charAt(0)}
                                        </span>
                            <UncontrolledTooltip
                              placement="top"
                              target={"member" + member.id}
                            >
                              {member.name}
                            </UncontrolledTooltip>
                          </div>
                        </Link>
                      </div>
                    )
                )}
              </div>
            </td>
            <td>
              <UncontrolledDropdown>
                <DropdownToggle
                  className="card-drop"
                  tag="a"
                >
                  <i className="mdi mdi-dots-horizontal font-size-18" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  {course.status !== 'published' && <DropdownItem
                    onClick={() => dispatch(coursePublish(course.id))}
                  >
                    <i className="bx bx-badge-check font-size-16 text-success me-1" />{" "}
                    Publish
                  </DropdownItem>}
                  <DropdownItem
                    href="#"
                    onClick={() => {
                      setForm(!form)
                      setCourseId(course.id)
                    }}
                  >
                    <i className="mdi mdi-book-edit font-size-16 text-warning me-1" />{" "}
                    Edit
                  </DropdownItem>
                  {(course.lessons === 0 || course.status !== "published")&& <DropdownItem
                    href="#"
                    onClick={() => dispatch(courseDelete(course.id))}
                  >
                    <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                    Delete
                  </DropdownItem>}
                </DropdownMenu>
              </UncontrolledDropdown>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
    </div>
    <Row>
      <Col xs="12">
        <div className="text-center my-3">
          {loading && <Link to="#" className="text-success">
            <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
            Load more
          </Link>}
        </div>
      </Col>
    </Row>

    <CourseFormCanvas courseId={courseId} isOpen={form} callBack={(fields) => {
      setForm(false)
      dispatch(courseSubmit(fields))
      dispatch(courseGet())
    }} toggle={() => setForm(!form)} categoryId={0} />
  </>
}
