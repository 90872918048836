import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Container } from "reactstrap"
import { Player } from "video-react"

import "video-react/dist/video-react.css"
import { videoFind } from "../../store/video/videoActions"

export const VideoPlayer = ({ video }) => {
  return <video
    src={video}
    style={{width: '100%'}}
    // ref={videoRef}
    controls
  />
}


const PlayVideo = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { Videos } = useSelector(state => state)
  const { video } = Videos
  const get = () => dispatch(videoFind(id))
  useEffect(() => {
    get()
  }, [])

  return <React.Fragment>
    <div className="page-content">
      <Container fluid>
        {video && <VideoPlayer
          playsInline
          video={video} />}

      </Container>
    </div>

  </React.Fragment>


}

export default PlayVideo

